import {
	USER_COLLECTION,
	USER_FEEDBACK_COLLECTION,
	USER_MEDIA_METADATA_COLLECTION,
	WEBINAR_ATTENDED_COLLECTION
} from '../../AppConstants/CollectionConstants';
import { ScorePoints } from '../../AppConstants/ScorePoints';
import firebase, { firestore } from '../../firebase/firebase';

export const watchedContent = async (videoid, userid) => {
	try {
		const id = userid + videoid;
		const attendedDoc = await firestore.collection(USER_MEDIA_METADATA_COLLECTION).doc(id).get();
		if (!attendedDoc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No Metadata found'
			};

			throw err;
		}

		// Check already rewarded or not
		if (attendedDoc.data() && attendedDoc.data().rewarded) {
			return 'Already rewarded';
		}

		const userRef = firestore.collection(USER_COLLECTION).doc(userid);

		// Update user score
		await firestore.runTransaction(async (t) => {
			const userDoc = await t.get(userRef);
			if (!userDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User found'
				};

				throw err;
			}

			t.update(userRef, {
				score: userDoc.data().score
					? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.VIDEO_COMPLETE))
					: ScorePoints.VIDEO_COMPLETE
			});
		});

		// Update that the user is rewarded
		await firestore.collection(USER_MEDIA_METADATA_COLLECTION).doc(id).update({
			rewarded: true
		});

		return 'Rewarded for video';
	} catch (error) {
		console.log('videoWatched scoring manager', error);
		throw error;
	}
};

export const watchedWebinar = async (webinarid, userid) => {
	try {
		const id = webinarid + '_' + userid;
		const attendedDoc = await firestore.collection(WEBINAR_ATTENDED_COLLECTION).doc(id).get();
		if (!attendedDoc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No Attendence found'
			};

			throw err;
		}

		// Check already rewarded or not
		const attendedData = attendedDoc.data();
		if (attendedData && attendedData.rewarded) {
			return 'Already rewarded';
		}

		const userRef = firestore.collection(USER_COLLECTION).doc(userid);

		// Update user score
		await firestore.runTransaction(async (t) => {
			const userDoc = await t.get(userRef);
			if (!userDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User found'
				};

				throw err;
			}

			const userData = userDoc.data();

			t.update(userRef, {
				score: userData.score
					? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.WEBINAR_ATTENDED))
					: ScorePoints.WEBINAR_ATTENDED
			});
		});

		// Update that the user is rewarded
		await firestore.collection(WEBINAR_ATTENDED_COLLECTION).doc(id).update({
			rewarded: true
		});

		return 'Rewarded for attending webinar';
	} catch (error) {
		console.log('videoWatched scoring manager', error);
		throw error;
	}
};

export const pointsOnShare = async (userid, videoId = null) => {
	try {
		const userRef = firestore.collection(USER_COLLECTION).doc(userid);

		// Update user score
		await firestore.runTransaction(async (t) => {
			const userDoc = await t.get(userRef);
			if (!userDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User found'
				};

				throw err;
			}

			const userData = userDoc.data();

			t.update(userRef, {
				score: userData.score
					? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.SHARE))
					: ScorePoints.SHARE
			});
		});

		return 'Rewarded for share invite';
	} catch (error) {
		console.log('share invite scoring manager', error);
		throw error;
	}
};

export const pointsOnChat = async (userid, videoId = null) => {
	try {
		const userRef = firestore.collection(USER_COLLECTION).doc(userid);

		// Update user score
		await firestore.runTransaction(async (t) => {
			const userDoc = await t.get(userRef);
			if (!userDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User found'
				};

				throw err;
			}

			const userData = userDoc.data();

			t.update(userRef, {
				score: userData.score
					? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.NETWORKING))
					: ScorePoints.SHARE
			});
		});

		return 'Rewarded for share invite';
	} catch (error) {
		console.log('share invite scoring manager', error);
		throw error;
	}
};

export const giveFeedbackPoints = async (userid, webinarid) => {
	try {
		const query = firestore
			.collection(USER_FEEDBACK_COLLECTION)
			.where('userId', '==', userid)
			.where('eventId', '==', webinarid)
			.limit(1);
		const docData = await query.get();
		if (docData.empty) {
			const userRef = firestore.collection(USER_COLLECTION).doc(userid);

			// Update user score
			await firestore.runTransaction(async (t) => {
				const userDoc = await t.get(userRef);
				if (!userDoc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No User found'
					};

					throw err;
				}

				const userData = userDoc.data();

				t.update(userRef, {
					score: userData.score
						? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.FEEDBACK))
						: ScorePoints.FEEDBACK
				});
			});

			return 'Rewarded for feedback';
		} else {
			return 'Points already allocated';
		}
	} catch (error) {
		console.log('feedback submittion scoring manager', error);
		throw error;
	}
};

const ScoreManager = {
	watchedContent,
	watchedWebinar,
	pointsOnShare,
	giveFeedbackPoints,
	pointsOnChat
};

export default ScoreManager;
