import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { CloudFunctionName } from "../../AppConstants/CloudFunctionName";
import {
  SITE_TIMER_IN_MINUTES,
  instituteValues,
} from "../../AppConstants/Constants";
import { FirebaseErrorCodes } from "../../AppConstants/FirebaseErrorCodes";
import { ExclusiveType } from "../../AppConstants/TypeConstants";
import {
  AuthManager,
  checkLandingPageSession,
  cloudFunction_as,
  updateLandingPageSession,
} from "../../firebase/firebase";
import UserManager from "../../Managers/Users";
import { resetUserLoginHook } from "../../utils/UserLoginHook";
import { UserContext } from "../UserContext/UserContextProvider";
import { useLocation, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { SPECIALITY_DATA } from "../../AppData/Speciality";
import { checkObjectNull } from "../../utils/common.utils";
import { LOGIN_CLICK } from "../../AppConstants/AnalyticsEventName";
import { AnalyticsContext } from "../Analytics/AnalyticsContextProvider";

export const authContext = createContext();

const DefaultFormDetails = {
  firstName: "",
  lastName: "",
  email: "",
  profession: "",
  speciality: "",
  areaOfInterest: [],
  city: {
    state: "",
    city: "",
    country: "",
    pincode: null,
    location: {
      lat: 20.5937,
      lng: 78.9629,
    },
  },
  phoneNumber: "",
  year: "",
  institute: "",
  regId: "",
  terms: true,
  marketing: true,
};

const initialState = {
  showSignInModal: false,
  showJoinModal: false,
  showSignUpModal: false,
  showOTPModal: false,
  showProfile: true,
  cancelled: false,
  showOTPScreen: false,
  showPersonalDetailsScreen: false,
  premiumFormModalStart: false,
  premiumFormModal: false,
  premiumFormModalComplete: false,
  exclusiveFormModalStart: false,
  exclusiveFormModal: false,
  exclusiveFormModalComplete: false,
  loading: false,
  alreadyRegistered: false,
  formDetails: DefaultFormDetails,
  error: false,
  errors: false,
  userDisabledError: false,
  isPreFilledData: false,
  returnURlToRedirect: "",
  utm_source: "",
  utm_medium: "",
  utm_campaign: "",
};

const Action_Type = {
  handleToggleForm: "handleToggleForm",
  handleFormDetails: "handleFormDetails",
  handleInputChange: "handleInputChange",
  updateMultipleState: "updateMultipleState",
  handleInitialInput: "handleInitialInput",
  updateUTM: "updateUTM",
  handleSignUpBtn: "handleSignUpBtn",
  handleError: "handleError",
  loading: "loading",
};

const reducer = (state, action) => {
  let { type, payload } = action;

  switch (type) {
    case Action_Type.handleInitialInput:
    case Action_Type.handleToggleForm:
    case Action_Type.handleError:
    case Action_Type.loading:
    case Action_Type.updateMultipleState:
    case Action_Type.updateUTM:
    case Action_Type.handleSignUpBtn:
      return {
        ...state,
        ...payload,
      };
    case Action_Type.handleFormDetails:
      return {
        ...state,
        formDetails: {
          ...state.formDetails,
          ...payload.formDetails,
          error: false,
          userDisabledError: false,
        },
      };
    case Action_Type.handleInputChange:
      return { ...state, ...payload, [payload.name]: payload.value };
    default:
      return { ...state };
  }
};
const INITIAL_TIME_IN_SECONDS = SITE_TIMER_IN_MINUTES * 60; // Initial time in seconds
export default function AuthContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useContext(UserContext);
  const [globalTimer, setGlobalTimer] = useState(INITIAL_TIME_IN_SECONDS);
  const { addGAWithNoUser } = useContext(AnalyticsContext);
  const { pathname, search = "", hash = "" } = useLocation();
  const history = useHistory();
  const checkDocAPIOnce = useRef(true);

  const params = new URLSearchParams(decodeURIComponent(search));

  useEffect(() => {
    let returnURL = params.get("returnURL");
    let obj = {
      utm_source: params.get("utm_source"),
      utm_medium: params.get("utm_medium"),
      utm_campaign: params.get("utm_campaign"),
    };
    if (returnURL) {
      let utm_source = new URLSearchParams(
        decodeURIComponent(returnURL?.split("?")[1])
      ).get("utm_source");
      obj["utm_source"] = utm_source;
    }
    if (!user) {
      if (
        !checkObjectNull(obj) &&
        !window.location.pathname.startsWith("/series/") &&
        !window.location.pathname.startsWith("/video/")
      ) {
        updateMultipleState({
          showJoinModal: true,
        });
      }
    }
    dispatch({
      type: Action_Type.updateUTM,
      payload: obj,
    });
  }, [search, user]);

  useEffect(() => {
    try {
      if (params && checkDocAPIOnce.current) {
        checkDocAPIOnce.current = false;
        // if already logged in
        if (user) {
          let authString = params.get("auth");
          let returnURL = params.get("returnURL");
          let authParams = new URLSearchParams(authString);
          let authEntries = authParams.entries();
          let obj = Object.fromEntries(authEntries);
          if (!isEmpty(obj)) {
            // handle login
            if (obj.uid && obj.phoneNumber) {
              // if same user tries to login then do nothing
              if (user.uid === obj?.uid) {
                updateMultipleState({
                  returnURLToRedirect: returnURL,
                });
                return;
              } else {
                // different user trying to login
                updateMultipleState({
                  returnURLToRedirect: returnURL,
                });
                setTimeout(() => {
                  AuthManager.logout();
                  handlePhoneNumber(obj.phoneNumber);
                  handleLoginBtn(null, obj.phoneNumber);
                  // handle redirect url
                }, 2000);
              }
            } else if (obj.phoneNumber && !obj.uid) {
              // register user logout and it will handle in our else case
              setTimeout(() => {
                checkDocAPIOnce.current = true;
                AuthManager.logout();
              }, 2000);
            }
          }
        } else if (search) {
          let _authString = params.get("auth");
          let _authParams = new URLSearchParams(_authString);
          let authEntries = _authParams.entries();
          let returnURLToRedirect = params.get("returnURL");

          let obj = {
            phoneNumber: "",
            uid: "",
          };

          obj = Object.fromEntries(authEntries);
          if (!_authString) {
            let returnURL = params.get("returnURL");
            let returnURLParams = new URLSearchParams(
              decodeURIComponent(returnURL?.split("?")[1])
            );

            let authString = returnURLParams.get("auth");
            returnURLToRedirect = returnURLParams.get("returnURL");

            let authParams = new URLSearchParams(authString);

            obj.phoneNumber = returnURLParams.get("phoneNumber");
            obj.uid = authParams.get("uid");
            obj.firstName = authParams.get("firstName");

            let returnURLParamsEntries = returnURLParams.entries();
            obj = { ...obj, ...Object.fromEntries(returnURLParamsEntries) };
          }
          // login flow
          if (obj.phoneNumber && obj.uid) {
            handlePhoneNumber(obj.phoneNumber);
            handleLoginBtn(null, obj.phoneNumber);
            updateMultipleState({ returnURLToRedirect: returnURLToRedirect });
          } else if (obj.phoneNumber && !obj.uid) {
            // register flow
            registerNewHiDOCUser(obj);
            updateMultipleState({ returnURLToRedirect: returnURLToRedirect });
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  useEffect(() => {
    var timer = 0;
    if (
      (!window.location.pathname.startsWith("/series/") &&
        !window.location.pathname.startsWith("/video/")) ||
      state.showSignInModal ||
      user
    ) {
      clearTimeout(timer);
      return;
    }

    if (globalTimer >= INITIAL_TIME_IN_SECONDS) {
      setTimeout(() => {
        checkLandingSessions((res) => {
          if (!res) {
            updateLandingPageSession();
          }
          // if already expired then remove timer
          if (res) {
            clearTimeout(timer);
            return;
          }
        });
      }, 700);
    }
    if (!user) {
      timer = setTimeout(() => {
        if (globalTimer > 0) {
          setGlobalTimer((prevTime) => prevTime - 1); // Decrease time by 1 second
        } else {
          updateLandingPageSession();
          clearTimeout(timer); // Clear the timer when it reaches 0
          // Here, you can add any action you want to perform when the timer finishes
          toggleSignInbtn("showSignInModal", true);
        }
      }, 1000); // Runs every second
    } else {
      clearTimeout(timer);
    }
    return () => clearTimeout(timer); // Clean up the timer on component unmount
  }, [globalTimer, user]); // Re-run effect whenever 'time' changes

  const checkLandingSessions = async (cb = () => {}) => {
    let res = await checkLandingPageSession();
    cb(res);
    toggleSignInbtn("showSignInModal", res);
  };

  const toggleSignInbtn = (type, bool) => {
    let obj = {
      [type]: bool,
    };
    if (type === "showSignInModal" && !bool) {
      // to reset userLoginHooks if any attached
      let formDetails = {
        phoneNumber: "",
      };
      Object.assign(obj, { formDetails: formDetails });

      resetUserLoginHook();
    }
    dispatchData(Action_Type.handleToggleForm, obj);
  };
  const handleLoading = (bool) => {
    let obj = {
      loading: bool,
    };
    dispatchData(Action_Type.loading, obj);
  };
  const handleFormDetails = (data) => {
    let obj = {
      formDetails: { ...data },
    };
    dispatchData(Action_Type.handleFormDetails, obj);
  };
  const updateMultipleState = (data) => {
    let obj = {
      ...data,
    };
    dispatchData(Action_Type.updateMultipleState, obj);
  };

  const dispatchData = (type, payload) => {
    dispatch({
      type: type,
      payload: payload,
    });
  };
  const toggleSignUpBtn = () => {
    let obj = {
      ...initialState,
      showSignUpModal: true,
    };
    dispatchData(Action_Type.handleSignUpBtn, obj);
  };
  const resetState = () => {
    dispatchData(Action_Type.handleSignUpBtn, initialState);
  };

  const closeSignUpModal = () => {
    let obj = {
      ...initialState,
      showSignUpModal: false,
    };
    dispatchData(Action_Type.handleSignUpBtn, obj);
    // to rest userLoginHooks if any attached
    resetUserLoginHook();
  };

  const handlePhoneNumber = (number) => {
    let obj = {
      formDetails: { ...state.formDetails, phoneNumber: number },
    };
    dispatchData(Action_Type.handleFormDetails, obj);
  };

  const toggleSignUpScreens = ({ type, value }) => {
    if (type === "showprofile") {
      let obj = {
        showOTPScreen: false,
        showProfile: value,
      };
      updateMultipleState(obj);
      return;
    }
    if (type === "showotp") {
      let obj = {
        showProfile: false,
        showOTPScreen: value,
      };
      updateMultipleState(obj);
      return;
    }
    if (type === "showpersonaldetails") {
      let obj = {
        showOTPScreen: false,
        showPersonalDetailsScreen: value,
      };
      updateMultipleState(obj);
      return;
    }
  };

  const handleLoginBtn = async (e, number = null) => {
    try {
      if (e) {
        e.preventDefault();
      }
      let _phoneNumberTOuse = number ? number : state.formDetails.phoneNumber;
      if (!_phoneNumberTOuse || !isValidPhoneNumber(_phoneNumberTOuse.trim())) {
        let obj = { errors: "Please enter valid number.", loading: false };
        dispatchData(Action_Type.handleError, obj);
        return;
      }
      let obj = {
        errors: false,
        error: false,
        userDisabledError: false,
        loading: true,
      };
      dispatchData(Action_Type.handleError, obj);

      let _phoneNumber = _phoneNumberTOuse.trim();
      let email = `${_phoneNumber}@docflix.com`;
      let pwd = _phoneNumber;

      let _u = await AuthManager.login(email, pwd);
      addGAWithNoUser(LOGIN_CLICK, { uid: _u.uid || "" });
      handleLoading(false);
      obj.loading = false;
      obj.showSignInModal = false;
      dispatchData(Action_Type.handleError, obj);
    } catch (error) {
      console.error(error);
      if (error.code === FirebaseErrorCodes.AUTH_USER_NOT_FOUND) {
        let obj = { error: true, loading: false };
        dispatchData(Action_Type.handleError, obj);
      }
      if (error.code === FirebaseErrorCodes.USER_DISABLED) {
        // let obj = { userDisabledError: true, loading: false };
        let obj = { error: true, loading: false };
        dispatchData(Action_Type.handleError, obj);
      }
    }
  };

  const registerNewHiDOCUser = (obj) => {
    let _newUserData = {
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      firstName: obj.firstName,
      lastName: obj.lastName,
      profession: "",
      speciality: "",
      geoHash: null,

      termsAndConditions: false,
      marketing: false,
      date: new Date().getTime(),
      utm_source: "",
      utm_medium: "",
      utm_campaign: "",
      automation: true,
      sessionId: obj.sessionid,
      userMetaData: {
        registrationSource: obj.source || "ott", //partiallyRegistered
      },
    };

    if (obj.profession) {
      _newUserData["profession"] = obj?.profession || "Doctor";
    }

    if (obj.speciality) {
      _newUserData["speciality"] = SPECIALITY_DATA.includes(obj.speciality)
        ? obj.speciality
        : "";
    }

    if (obj.gmaps == "true") {
      let city = {
        state: obj.state,
        city: obj.city,
        country: obj.country,
        pincode: null,
        // location: {
        //   lat: +obj.latitude,
        //   lng: +obj.longitude,
        // },
        location: null,
      };
      _newUserData["city"] = city;
    }

    handleFormDetails(_newUserData);

    updateMultipleState({
      showJoinModal: true,
      showSignInModal: false,
      isPreFilledData: `${obj.city ? obj.city + ", " : ""}${
        obj.state ? obj.state + ", " : ""
      }${obj.country ? obj.country : ""}`,
    });
  };

  const handleRegisterBtn = () => {
    handleLoading(true);
    const cloudRef = cloudFunction_as.httpsCallable(
      CloudFunctionName.USER_CREATION_DEV
    );
    let registeration_source = localStorage.getItem("registeration_source");

    cloudRef(
      JSON.stringify({
        email: state.formDetails.email,
        phoneNumber: state.formDetails.phoneNumber,
        firstName: state.formDetails.firstName,
        lastName: state.formDetails.lastName,
        profession: state.formDetails.profession,
        speciality: state.formDetails.speciality,
        areaOfInterest: [state.formDetails.areaOfInterest],
        current_plan: ExclusiveType.PREMIUM,
        ...state.formDetails.city,
        termsAndConditions: state.formDetails.terms,
        marketing: state.formDetails.marketing,
        sessionId: state?.sessionId,
        date: new Date().getTime(),
        userMetaData: {
          registrationSource:
            state.formDetails?.userMetaData?.registrationSource ||
            registeration_source ||
            "ott",
        },
      })
    )
      .then(async (res) => {
        localStorage.removeItem("registeration_source");

        let _phoneNumber = state.formDetails.phoneNumber.trim();
        let email = `${_phoneNumber}@docflix.com`;
        let pwd = _phoneNumber;
        await AuthManager.login(email, pwd);
        handleLoading(false);

        if (!res.data.userId) {
          window.alert("Please Try Again Later");
          handleLoading(false);
          return;
        }
      })
      .catch((err) => {
        console.error(err);
        window.errObj = err;
        if (err.code === FirebaseErrorCodes.USER_EXISTS_CLOUD_FUCTION) {
          updateMultipleState({ alreadyRegistered: true });
        }
        handleLoading(false);
      });
  };
  const updateProfile = () => {
    handleLoading(true);
    let errors = validatePremiumForm();

    if (!isValidForm(errors)) {
      dispatch({
        type: Action_Type.loading,
        payload: { loading: false },
      });
      return;
    }
    const cloudRef = cloudFunction_as.httpsCallable(
      CloudFunctionName.USER_PROFILE_UPDATE
    );
    let obj = {
      userId: user.uid,
      profession: state.formDetails.profession,
      speciality: state.formDetails.speciality,
      areaOfInterest: [state.formDetails.areaOfInterest],
      current_plan: ExclusiveType.PREMIUM,
      ...state.formDetails.city,
    };

    cloudRef(JSON.stringify(obj))
      .then(async (res) => {
        let obj = {
          premiumFormModal: false,
          premiumFormModalComplete: true,
          loading: false,
          errors: false,
        };
        updateMultipleState(obj);
      })
      .catch((err) => {
        console.error(err);
        let obj = { errors: false, loading: false };
        dispatchData(Action_Type.handleError, obj);
      });
  };

  const handleInitialCancelled = () => {
    dispatch({
      type: Action_Type.handleInitialInput,
      payload: { cancelled: false },
    });
  };

  const validateDoctor = async () => {
    handleLoading(true);
    let errors = validateExclusiveForm();

    if (!isValidForm(errors)) {
      dispatch({
        type: Action_Type.loading,
        payload: { loading: false },
      });
      return;
    }
    let smcId = instituteValues[state.formDetails.institute].smcId;
    let obj = {
      year: state.formDetails.year,
      regId: state.formDetails.regId,
      institute: state.formDetails.institute,
    };

    await UserManager._get
      .verifyDoctor(user.uid, obj, smcId, ExclusiveType.EXCLUSIVE)
      .then((res) => {
        let obj = {
          exclusiveFormModal: false,
          exclusiveFormModalComplete: true,
          loading: false,
          errors: false,
        };
        updateMultipleState(obj);
      })
      .catch((err) => {
        console.error(err);
        let obj = { errors: { regId: err.message }, loading: false };
        dispatchData(Action_Type.handleError, obj);
      });
  };

  const validateExclusiveForm = () => {
    let errors = {};
    errors.year =
      state.formDetails.year.trim().length > 0 ? "" : "Year is required";
    errors.regId =
      state.formDetails.regId.trim().length > 0
        ? ""
        : "Registration number is required";
    errors.institute =
      state.formDetails.institute.trim().length > 0
        ? ""
        : "State medical council is required";
    let obj = { errors: errors };
    dispatchData(Action_Type.handleError, obj);
    return errors;
  };
  const validatePremiumForm = () => {
    let errors = {};
    errors.profession =
      state.formDetails.profession.length > 0 ? "" : "Profession is required";
    errors.speciality =
      state.formDetails.speciality.length > 0
        ? ""
        : "Speciality number is required";
    errors.areaOfInterest =
      state.formDetails.areaOfInterest.length > 0
        ? ""
        : "Area of Interest is required";

    errors.city =
      state.formDetails.city["city"].length > 0 ? "" : "City is required";

    let obj = { errors: errors };
    dispatchData(Action_Type.handleError, obj);
    return errors;
  };

  const isValidForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
  };

  useEffect(() => {
    if (
      state.premiumFormModalStart === true ||
      state.premiumFormModal === true ||
      state.premiumFormModalComplete === true ||
      state.exclusiveFormModalStart === true ||
      state.exclusiveFormModal === true ||
      state.exclusiveFormModalComplete === true
    ) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [state]);

  const handleCloseAllForms = () => {
    updateMultipleState({
      exclusiveFormModal: false,
      premiumFormModal: false,
      exclusiveFormModalStart: false,
      premiumFormModalStart: false,
      premiumFormModalComplete: false,
      exclusiveFormModalComplete: false,
      cancelled: true,
    });
  };

  useEffect(() => {
    if (user) {
      resetState();
    }
  }, [user]);

  useEffect(() => {
    if (user && state.returnURLToRedirect) {
      let _returnURL = state.returnURLToRedirect;
      history.replace(_returnURL);
      updateMultipleState({
        returnURLToRedirect: "",
      });
    }
  }, [user, state.returnURLToRedirect]);

  return (
    <authContext.Provider
      value={{
        toggleSignInbtn,
        handleFormDetails,
        handleLoading,
        handleInitialCancelled,
        state,
        cancelled: JSON.parse(JSON.stringify(state.cancelled)),
        handleLoginBtn,
        handlePhoneNumber,
        updateMultipleState,
        toggleSignUpBtn,
        toggleSignUpScreens,
        resetState,
        closeSignUpModal,
        handleRegisterBtn,
        updateProfile,
        validateDoctor,
        handleCloseAllForms,
        DefaultFormDetails,
        globalTimer,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
}
