import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import close from "../../../assets/images/searchClose.png";
import { isMobileDevice } from "../../../utils/getPlatformInfo";

const FilterComponent = ({
  showFilterTab,
  setShowFilterTab,
  submitFilter,
  filters,
}) => {
  const [showFilter, setShowFilterinMob] = useState(showFilterTab);
  const [addedFilter, setAddedFilter] = useState({});
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterValues, setFilterValues] = useState(filters);

  useEffect(() => {
    setFilterValues(filters);
  }, [filters]);

  useEffect(() => {
    setShowFilterinMob(showFilterTab);
    isMobileDevice && setAddedFilter(appliedFilter);
  }, [showFilterTab]);

  useEffect(() => {
    submitFilter(isMobileDevice ? appliedFilter : addedFilter);
  }, [isMobileDevice ? appliedFilter : addedFilter]);

  const handleFilterHide = (filters) => {
    const updatedFilters = { ...filterValues };
    updatedFilters[filters].hide = !updatedFilters[filters].hide;
    setFilterValues(updatedFilters);
  };

  const handleFilter = (value, filter) => {
    let keyName = filter.title;
    let appliedFilters = { ...addedFilter };
    if (!value) {
      appliedFilters[keyName].values = appliedFilters[keyName].values.filter(
        (key) => key != filter.value
      );
      appliedFilters[keyName].values.length < 1 &&
        delete appliedFilters[keyName];
    } else if (appliedFilters[keyName]) {
      appliedFilters[keyName].values.push(filter.value);
    } else {
      appliedFilters[keyName] = {
        path: keyName,
        values: [filter.value],
      };
    }
    setAddedFilter(appliedFilters);
  };

  return (
    <div
      className={`${styles.filterWrapper} ${
        showFilter ? "" : styles.showFilterinMob
      }`}
    >
      <div className={styles.topWrapper}>
        <h3>Filters</h3>
        <div className={styles.btnwrapper}>
          {isMobileDevice && (
            <button
              onClick={() => {
                setAppliedFilter(addedFilter);
                setShowFilterinMob(false);
                setShowFilterTab(false);
              }}
            >
              Apply
            </button>
          )}
          <button
            onClick={() => {
              isMobileDevice && setAppliedFilter({});
              setAddedFilter({});
            }}
          >
            Clear All
          </button>
        </div>
        <img
          className={styles.filterClose}
          // onClick={handleCloseSearch}
          onClick={() => {
            setShowFilterinMob(false);
            setShowFilterTab(false);
          }}
          style={{
            cursor: "pointer",
          }}
          src={close}
          alt="close"
        />
      </div>
      <div className={styles.filterSectionWrapper}>
        {Object.keys(filterValues)
          .reverse()
          ?.map((filters, index) => (
            <div className={styles.filterSection} key={index}>
              <div className={styles.FilterHead}>
                <h5>{filters}</h5>
                {filterValues[filters].hide ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="11"
                    viewBox="0 0 22 11"
                    fill="none"
                    onClick={() => handleFilterHide(filters)}
                  >
                    <path
                      d="M1.70778 0.288774L10.2967 8.74205C10.4841 8.92744 10.7371 9.03143 11.0007 9.03143C11.2643 9.03143 11.5173 8.92744 11.7047 8.74205L20.2936 0.290607C20.4822 0.105307 20.736 0.00147629 21.0003 0.00147629C21.2647 0.00147629 21.5185 0.105307 21.7071 0.290607C21.7999 0.381129 21.8737 0.489322 21.9241 0.608809C21.9745 0.728297 22.0004 0.856663 22.0004 0.986339C22.0004 1.11602 21.9745 1.24438 21.9241 1.36387C21.8737 1.48335 21.7999 1.59155 21.7071 1.68207L13.12 10.1335C12.5544 10.6889 11.7934 11 11.0007 11C10.208 11 9.44703 10.6889 8.88142 10.1335L0.294317 1.68207C0.201183 1.59152 0.127151 1.48321 0.0765985 1.36356C0.0260462 1.2439 0 1.11532 0 0.985421C0 0.855523 0.0260462 0.726946 0.0765985 0.607288C0.127151 0.487632 0.201183 0.379324 0.294317 0.288774C0.48288 0.103473 0.736677 -0.000357628 1.00105 -0.000357628C1.26542 -0.000357628 1.51922 0.103473 1.70778 0.288774Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="11"
                    viewBox="0 0 22 11"
                    fill="none"
                    style={{ transform: "rotate(180deg)" }}
                    onClick={() => handleFilterHide(filters)}
                  >
                    <path
                      d="M1.70778 0.288774L10.2967 8.74205C10.4841 8.92744 10.7371 9.03143 11.0007 9.03143C11.2643 9.03143 11.5173 8.92744 11.7047 8.74205L20.2936 0.290607C20.4822 0.105307 20.736 0.00147629 21.0003 0.00147629C21.2647 0.00147629 21.5185 0.105307 21.7071 0.290607C21.7999 0.381129 21.8737 0.489322 21.9241 0.608809C21.9745 0.728297 22.0004 0.856663 22.0004 0.986339C22.0004 1.11602 21.9745 1.24438 21.9241 1.36387C21.8737 1.48335 21.7999 1.59155 21.7071 1.68207L13.12 10.1335C12.5544 10.6889 11.7934 11 11.0007 11C10.208 11 9.44703 10.6889 8.88142 10.1335L0.294317 1.68207C0.201183 1.59152 0.127151 1.48321 0.0765985 1.36356C0.0260462 1.2439 0 1.11532 0 0.985421C0 0.855523 0.0260462 0.726946 0.0765985 0.607288C0.127151 0.487632 0.201183 0.379324 0.294317 0.288774C0.48288 0.103473 0.736677 -0.000357628 1.00105 -0.000357628C1.26542 -0.000357628 1.51922 0.103473 1.70778 0.288774Z"
                      fill="white"
                    />
                  </svg>
                )}
              </div>

              {!filterValues[filters].hide && (
                <div className={styles.filters}>
                  {filters &&
                    filterValues[filters]?.buckets?.map(
                      (value, index) =>
                        value._id !== "Podcast" && (
                          <button
                            key={index}
                            // onClick={()=>handleFilter(`${filters}_${value._id}`)}
                          >
                            <input
                              type="checkbox"
                              checked={
                                addedFilter[filters]
                                  ? addedFilter[filters]?.values.includes(
                                      value._id
                                    )
                                  : false
                              }
                              onChange={(e) =>
                                handleFilter(e.target.checked, {
                                  title: filters,
                                  value: value._id,
                                })
                              }
                            />
                            <p>{value._id}</p>
                          </button>
                        )
                    )}
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default FilterComponent;
