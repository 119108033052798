import { Suspense, useEffect } from "react";
import Router from "./router";
import AOS from "aos";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// aos
import "aos/dist/aos.css";
import "animate.css";
import { Loader } from "./components/RestrictedMessage/RestrictedMessage";
import AppInstallPopUp from "./components/AppInstallPopUp";
// import SearchModal from "./components/NavHeader/SearchModal";
import SearchModal from "./components/NavHeader/NewSearchModal";
import { openRoutes } from "./AppConstants/Constants";
// import useNetwork from "./hooks/useNetwork";
// import InternetPopUp from "./components/InternetPopUp";
// import MouseParticles from "react-mouse-particles";

const App = () => {
  useEffect(() => {
    AOS.init({});
  }, []);
  // const exists = openRoutes.some((hideRoute) =>
  //   window.location.pathname.includes(hideRoute)
  // );
  return (
    <Suspense fallback={<Loader />}>
      <Router />
      <ToastContainer />
      <AppInstallPopUp />
      {/* {showSearch && <SearchModal handleCloseSearch={handleCloseSearch} />} */}
      {/* {showNetworkToast && <InternetPopUp />} */}
      {/* <MouseParticles
          g={1}
          color="random"
          cull="MuiSvgIcon-root,MuiButton-root"
          level={6}
        /> */}
    </Suspense>
  );
};

export default App;
