import { createContext, useContext, useEffect, useState } from "react";
import aa from "search-insights";
import { algoliaCustomEvents } from "../../AppConstants/AlgoliaSearchConstants";
import { CloudFunctionName } from "../../AppConstants/CloudFunctionName";
import { MonthName } from "../../AppConstants/Months";
// import { isAndroid } from "react-device-detect";
import firebase, {
  analytics,
  cloudFunction_as,
  database,
} from "../../firebase/firebase";
import { isProd } from "../../utils/common.utils";
import { UserContext } from "../UserContext/UserContextProvider";
var uniqid = require("uniqid");

export const AnalyticsContext = createContext();

function getSessonId(uid) {
  return uniqid(`${uid}-`);
}

export default function AnalyticsContextProvider(props) {
  const { user, userBasicDetails: userInfo } = useContext(UserContext);
  const [sessionId, setSessionId] = useState(null);

  // useEffect(() => {
  //   if (user) {
  //     setSessionId(getSessonId(user.uid));
  //     if (window.matchMedia("(display-mode: standalone)").matches) {
  //       console.log("Inside PWA");
  //       analytics.logEvent("pwa_page_view");
  //     }
  //   } else {
  //     setSessionId(null);
  //   }
  // }, [user]);

  async function addGAWithUserInfo(eventName, data = {}) {
    try {
      // console.log(eventName, data, userInfo);
      // return;
      if (!user || !userInfo) {
        console.error("No UsrInfo Found");
        return;
      }
      let baseData = {
        userId: user.uid,
        profession: userInfo.profession,
        speciality: userInfo.speciality,
        country: userInfo.country,
        state: userInfo.state,
        city: userInfo.city,
        date: new Date(),
        dateTimeStamp: new Date().getTime(),
      };
      let wholeData = { ...baseData, ...data };
      // console.log(eventName, data, userInfo);
      isProd &&
        window.location.hostname.includes("docflix.com") &&
        analytics.logEvent(eventName, wholeData);
    } catch (error) {
      console.error(error);
    }
  }

  async function addGAWithNoUser(eventName, data = {}) {
    try {
      let baseData = {
        date: new Date(),
        dateTimeStamp: new Date().getTime(),
      };
      let wholeData = { ...baseData, ...data };
      // console.log(eventName, data, userInfo);
      isProd &&
        window.location.hostname.includes("docflix.com") &&
        analytics.logEvent(eventName, wholeData);
    } catch (error) {
      console.error(error);
    }
  }

  const SearchEvent = async (
    eventype,
    index,
    eventName,
    objectIDs,
    queryId = "queryid",
    position
  ) => {
    if (!userInfo) {
      console.error("No UsrInfo Found");
      return;
    }

    let obj = {};
    if (
      eventName === algoliaCustomEvents.searchView ||
      eventName === algoliaCustomEvents.searchClick
    ) {
      obj = {
        userToken: user.uid,
        index: index,
        eventName: eventName,
        objectIDs: objectIDs,
        queryId: queryId,
        positions: [position],
      };
    } else {
      obj = {
        userToken: user.uid,
        index: index,
        eventName: eventName,
        objectIDs: objectIDs,
      };
    }

    try {
      aa(eventype, obj);
    } catch (err) {
      console.error(err);
    }
  };

  //customIncDetails = {name, value}
  const addCAWithUserInfo = async (
    path,
    addUid,
    data = {},
    countInc = false,
    customIncDetails
  ) => {
    if (!userInfo) {
      console.error("No UsrInfo Found");
      return;
    }
    let baseData = {
      userId: user.uid,
      email: userInfo.email,
      phoneNumber: userInfo.phoneNumber,
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      profession: userInfo.profession,
      speciality: userInfo.speciality,
      country: userInfo.country,
      state: userInfo.state,
      city: userInfo.city,
      date: new Date(),
    };
    if (addUid) path += `/${user.uid}`;

    if (countInc) {
      baseData = {
        ...baseData,
        count: firebase.database.ServerValue.increment(1),
      };
    }

    if (customIncDetails) {
      baseData = {
        ...baseData,
        [customIncDetails.name]: firebase.database.ServerValue.increment(
          customIncDetails.value
        ),
      };
    }
    // console.log('cloudAnalytics', { ...baseData, ...data,})
    await database.ref(path).update({
      ...baseData,
      ...data,
      timestamp: firebase.database.ServerValue.TIMESTAMP,
    });
  };

  //Add Cloud Function based Analytics
  //sessionId , timestamps, date
  const updateUserStatus = async (eventId, timelineId, timespent) => {
    try {
      if (!sessionId) {
        console.error("SessionId is null");
        return;
      }
      if (!user) {
        console.error("userNotLogged");
        return;
      }
      if (!userInfo) {
        console.error("No info about user Found");
        return;
      }

      var currentDate = new Date();
      let dateString = `${currentDate.getDate()} ${
        MonthName[currentDate.getMonth()]
      } ${currentDate.getFullYear()} 00:00:00`;
      // console.log(dateString)
      var dateTimeStamp = new Date(dateString).getTime();
      let _sessionId = sessionId;
      if (!_sessionId) {
        _sessionId = getSessonId(user.uid);
        setSessionId(_sessionId);
      }

      let _data = {
        userId: user.uid,
        // basic info
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phoneNumber: userInfo.phoneNumber,
        profession: userInfo.profession,
        speciality: userInfo.speciality,
        country: userInfo.country,
        state: userInfo.state,
        city: userInfo.city,
        dateString: `${currentDate.getDate()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getFullYear()}`,
        //event based info
        eventId,
        timelineId,
        timespent,
        timestamp: currentDate.getTime(),
        date: dateTimeStamp,
        sessionId: _sessionId,
      };
      // console.log(_data);
      // dummyFunction(_data, user.uid)
      const cloudRef = cloudFunction_as.httpsCallable(
        CloudFunctionName.UPDATE_USER_STATUS
      );
      cloudRef(JSON.stringify(_data))
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          // console.log(err);
        });
      // const res = await cloudRef(JSON.stringify(_data))
      // console.log(res)
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        addGAWithUserInfo,
        addGAWithNoUser,
        addCAWithUserInfo,
        updateUserStatus,
        SearchEvent,
      }}
    >
      {/* {
        user &&
        <>
          <PWAInstaller />
          {
            !isAndroid &&
            <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false} />
          }
        </>
      } */}
      {props.children}
    </AnalyticsContext.Provider>
  );
}
