import { lazy } from "react";

const VideosPage = lazy(() => import("./VideosPage"));
const HomeScene = lazy(() => import("./HomeScene"));
const WebinarDetails = lazy(() => import("./WebinarDetails"));
const WebinarPage = lazy(() => import("./WebinarPage"));
const AcademyPage = lazy(() => import("./AcademyPage"));
const AcademyDetails = lazy(() => import("./AcademyDetails"));
const NetraDhamaEvent = lazy(() =>
  import("./OnDemandEventPage/NetraDhamaEvent")
);
const Notes = lazy(() => import("./Notes"));
const VideoSearchPage = lazy(() => import("./VideoSearchPage"));
const PlayListPage = lazy(() => import("./PlaylistPage"));
const SpeakerProfilePage = lazy(() =>
  import("./SpeakerProfilePage/SpeakerProfilePage")
);
const EmployeeDashboard = lazy(() => import("./EmployeeDashboard"));
const EmployeeDashboardTeamPage = lazy(() =>
  import("./EmployeeDashboard/TeamPage")
);
const EmployeeDashboardAllDoctorPage = lazy(() =>
  import("./EmployeeDashboard/AllDoctorsPage")
);
const EmployeeDashboardEmployeePage = lazy(() =>
  import("./EmployeeDashboard/EmployeePage")
);

const LandingPage = lazy(() => import("./LandingPage"));

const ReelsPage = lazy(() => import("./ReelsPage"));
const OurExpertPage=lazy(() => import("./OurExperts"));

export {
  VideosPage,
  HomeScene,
  WebinarDetails,
  WebinarPage,
  Notes,
  VideoSearchPage,
  SpeakerProfilePage,
  LandingPage,
  ReelsPage,
  PlayListPage,
  EmployeeDashboard,
  EmployeeDashboardTeamPage,
  EmployeeDashboardAllDoctorPage,
  EmployeeDashboardEmployeePage,
  AcademyPage,
  AcademyDetails,
  NetraDhamaEvent,
  OurExpertPage
};
