import { useState, createContext, useEffect, useContext } from "react";
import {
  ContentType,
  LikeType,
  LIKE_ACTION_TYPE,
} from "../../AppConstants/TypeConstants";
import LikeManager from "../../Managers/LikeManager";
import VideoLikesManager from "../../Managers/Video/likes";
import { UserContext } from "../UserContext/UserContextProvider";

export const LikeContext = createContext(null);
export default function LikeContextProvider(props) {
  const [data, setData] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!user) {
      return;
    }
    const getLikes = async () => {
      let __data = await VideoLikesManager._get.getLikes(user.uid);
      setData(__data);
    };
    getLikes();
  }, [user]);

  const addLike = async (targetType, targetId, isDislike = false) => {
    try {
      if (!user) {
        return;
      }
      let _targetType =
        targetType === ContentType.SERIES || targetType === ContentType.TRAILER
          ? ContentType.VIDEO
          : targetType;

      setData((prev) => {
        let i = { ...prev };
        if (i[targetId]) {
          i[targetId].actionType = isDislike
            ? LIKE_ACTION_TYPE.DISLIKE
            : LIKE_ACTION_TYPE.LIKE;
        } else {
          i[targetId] = {
            actionType: isDislike
              ? LIKE_ACTION_TYPE.DISLIKE
              : LIKE_ACTION_TYPE.LIKE,
          };
        }
        return i;
      });
      switch (_targetType) {
        case ContentType.SEASON:
        case ContentType.VIDEO:
        case ContentType.REEL:
        case ContentType.CHAPTER:
          await VideoLikesManager._post.createLike(
            _targetType,
            targetId,
            user.uid,
            isDislike
          );
          break;
        case ContentType.AGENDA:
          await LikeManager.postLike(_targetType, targetId, user.uid);
          break;
        default:
          console.error("No targetType passed");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteLike = async (targetType, targetId, isDislike = false) => {
    try {
      if (!user) {
        return;
      }
      let _targetType =
        targetType === ContentType.SERIES || targetType === ContentType.TRAILER
          ? ContentType.VIDEO
          : targetType;
      setData((prev) => {
        let i = { ...prev };
        if (i[targetId]) {
          delete i[targetId];
        }
        return i;
      });
      // console.log(targetType, targetId, "delete");
      switch (_targetType) {
        case ContentType.VIDEO:
        case ContentType.SEASON:
        case ContentType.REEL:
        case ContentType.CHAPTER:
          // await VideoLikesManager._delete.deleteLike(targetId, user.uid);
          await VideoLikesManager._post.createLike(
            _targetType,
            targetId,
            user.uid,
            isDislike
          );
          break;
        case ContentType.AGENDA:
          await LikeManager.deleteLike(_targetType, targetId, user.uid);
          break;
        default:
          console.error("No targetType passed");
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LikeContext.Provider value={{ likeData: data, addLike, deleteLike }}>
      {props.children}
    </LikeContext.Provider>
  );
}

export const useLikeContext = () => useContext(LikeContext);
