import firebase, { firestore } from "../../firebase/firebase";

const MARKETING_METADATA = "MarketingMetadata";

const addUncompleteVideo = async (
  video,
  userid,
  user,
  userMetaData,
  playDetails
) => {
  try {
    if (!userid) return "No userId";
    const metaDataRef = firestore.collection(MARKETING_METADATA).doc(userid);
    const metaDataDoc = await metaDataRef.get();
    if (!metaDataDoc.exists) {
      return metaDataRef.set({
        userid: userid,
        email: user.email,
        fullName: user.firstName + " " + user.lastName,
        phoneNumber: user.phoneNumber,
        marketing: user.marketing,
        registrationdate: user.timestamp,
        areaOfInterest: user.areaOfInterest || [],

        WebinarAttendList: [],
        WebinarInterest: [],

        lastUncompleteContent: [
          {
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          },
        ],
        lastCompleteContent: [],
        VideoCompleteCount: 0,

        lastContentViewedId: video.id,
        lastContentViewedTimestamp: new Date(),

        VideoTagViewed: video.tags,

        lastPlatformVisitTimestamp: new Date(),

        otpverified: userMetaData.otp_verified || false,
        otp_verified_timestamp: userMetaData.otp_verified_timestamp || null,
        timestamp: new Date(),
      });
    } else {
      return firestore.runTransaction(async (t) => {
        let doc = await t.get(metaDataRef);
        doc = doc.data();

        if (doc.lastUncompleteContent && doc.lastUncompleteContent.length) {
          // check if the video exists in the list
          const ids = doc.lastUncompleteContent.map((doc) => doc.id);
          const index = ids.indexOf(video.id);
          if (index !== -1) {
            doc.lastUncompleteContent.splice(index, 1);
          }
        }

        if (doc.lastCompleteContent && doc.lastCompleteContent.length) {
          // check if the video exists in the list
          const ids = doc.lastCompleteContent.map((doc) => doc.id);
          const index = ids.indexOf(video.id);
          if (index !== -1) {
            doc.lastCompleteContent.splice(index, 1);
          }
        }

        if (doc.lastUncompleteContent && doc.lastUncompleteContent.length > 4) {
          doc.lastUncompleteContent.shift();
          doc.lastUncompleteContent.push({
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          });
        } else {
          doc.lastUncompleteContent.push({
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          });
        }
        t.update(metaDataRef, {
          lastContentViewedId: video.id,
          lastContentViewedTimestamp: new Date(),
          VideoTagViewed: firebase.firestore.FieldValue.arrayUnion(
            ...video.tags
          ),
          lastCompleteContent: doc.lastCompleteContent,
          lastUncompleteContent: doc.lastUncompleteContent,
        });
      });
    }
  } catch (error) {
    console.error("Add Uncomplete Video to Marketing metadata", error);
    throw error;
  }
};

const addCompleteVideo = async (
  video,
  userid,
  user,
  userMetaData,
  playDetails
) => {
  try {
    if (!userid) return "No userId";
    const metaDataRef = firestore.collection(MARKETING_METADATA).doc(userid);
    const metaDataDoc = await metaDataRef.get();
    if (!metaDataDoc.exists) {
      return metaDataRef.set({
        userid: userid,
        email: user.email,
        fullName: user.firstName + " " + user.lastName,
        phoneNumber: user.phoneNumber,
        marketing: user.marketing,
        registrationdate: user.timestamp,
        areaOfInterest: user.areaOfInterest || [],

        WebinarAttendList: [],
        WebinarInterest: [],

        lastUncompleteContent: [],
        lastCompleteContent: [
          {
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          },
        ],
        VideoCompleteCount: 1,

        lastContentViewedId: video.id,
        lastContentViewedTimestamp: new Date(),

        VideoTagViewed: video.tags,

        lastPlatformVisitTimestamp: new Date(),

        otpverified: userMetaData.otp_verified || false,
        otp_verified_timestamp: userMetaData.otp_verified_timestamp || null,
        timestamp: new Date(),
      });
    } else {
      return firestore.runTransaction(async (t) => {
        let incrementValue = 1;
        let doc = await t.get(metaDataRef);
        doc = doc.data();

        if (doc.lastUncompleteContent && doc.lastUncompleteContent.length) {
          // check if the video exists in the list
          const ids = doc.lastUncompleteContent.map((doc) => doc.id);
          const index = ids.indexOf(video.id);
          if (index !== -1) {
            doc.lastUncompleteContent.splice(index, 1);
          }
        }

        if (doc.lastCompleteContent && doc.lastCompleteContent.length) {
          // check if the video exists in the list
          const ids = doc.lastCompleteContent.map((doc) => doc.id);
          const index = ids.indexOf(video.id);
          if (index !== -1) {
            doc.lastCompleteContent.splice(index, 1);
            incrementValue = 0;
          }
        }

        if (doc.lastCompleteContent && doc.lastCompleteContent.length > 4) {
          doc.lastCompleteContent.shift();
          doc.lastCompleteContent.push({
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          });
        } else {
          doc.lastCompleteContent.push({
            id: video.id,
            timestamp: new Date(),
            type: video.videoType,
            viewdetails: {
              totalDuration: video.duration,
              lastWatchTimestamp: playDetails.lastWatchTimestamp,
            },
          });
        }

        t.update(metaDataRef, {
          lastContentViewedId: video.id,
          lastContentViewedTimestamp: new Date(),
          VideoTagViewed: firebase.firestore.FieldValue.arrayUnion(
            ...video.tags
          ),
          lastCompleteContent: doc.lastCompleteContent,
          lastUncompleteContent: doc.lastUncompleteContent,
          VideoCompleteCount:
            firebase.firestore.FieldValue.increment(incrementValue),
        });
      });
    }
  } catch (error) {
    console.error("Add Complete Video to Marketing metadata", error);
    throw error;
  }
};

const addLoginTimestamp = async (userid, user, userMetaData) => {
  try {
    if (!userid) return "No userId";
    const metaDataRef = firestore.collection(MARKETING_METADATA).doc(userid);
    const metaDataDoc = await metaDataRef.get();
    if (!metaDataDoc.exists) {
      return metaDataRef.set({
        userid: userid,
        email: user.email,
        fullName: user.firstName + " " + user.lastName,
        phoneNumber: user.phoneNumber,
        marketing: user.marketing,
        registrationdate: user.timestamp,
        areaOfInterest: user.areaOfInterest || [],

        WebinarAttendList: [],
        WebinarInterest: [],

        lastUncompleteContent: [],
        lastCompleteContent: [],
        VideoCompleteCount: 0,

        lastContentViewedId: null,
        lastContentViewedTimestamp: new Date(),

        VideoTagViewed: [],

        lastPlatformVisitTimestamp: new Date(),

        otpverified: userMetaData.otp_verified || false,
        otp_verified_timestamp: userMetaData.otp_verified_timestamp || null,
        timestamp: new Date(),
      });
    } else {
      return firestore.runTransaction(async (t) => {
        t.update(metaDataRef, {
          lastPlatformVisitTimestamp: new Date(),
        });
      });
    }
  } catch (error) {
    console.error("Add login timestamp Marketing metadata", error);
    throw error;
  }
};

export const marketingMetaData = {
  addUncompleteVideo,
  addCompleteVideo,
  addLoginTimestamp,
};
