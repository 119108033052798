import { memo, useEffect } from "react";
import styles from "./index.module.scss";
import close from "../../../assets/images/searchClose.png";
import { VIDEO_SEARCH_CHAR_LIMIT } from "../../../AppConstants/Constants";

const SearchBox = ({
  selectedShow,
  currentRefinement,
  isSearchStalled,
  handleCloseSearch,
  refine,
  inputValue,
  setValue,
  resetValue,
  searchTag,
  changeResetValue,
  setSelectedShow,
}) => {
  const handleCloseSearchClick = () => {
    refine("");
    setValue("");
    changeResetValue(false);
    setSelectedShow("");
    handleCloseSearch();
  };
  const handleSubmitSearch = () => {
    // console.log("search");
  };
  useEffect(() => {
    if (resetValue) {
      refine("");
      changeResetValue(false);
    }
  }, [resetValue]);

  const handleInputChange = (event) => {
    setValue(event.currentTarget.value);
    if (event.currentTarget.value.length > VIDEO_SEARCH_CHAR_LIMIT) {
      refine(event.currentTarget.value);
    } else {
      refine("");
      changeResetValue(false);
    }
  };

  useEffect(() => {
    refine(selectedShow);
    setValue(selectedShow);
  }, [selectedShow]);

  return (
    <div className={styles.searchBoxWrapper}>
      <form
        noValidate
        // action=""
        className={styles.inputWrapper}
        onSubmit={(e) => e.preventDefault()}
        role="search"
      >
        <input
          className={styles.input}
          type="search"
          placeholder="Search Videos..."
          value={inputValue}
          onChange={handleInputChange}
        />
        <svg
          width="19"
          onClick={handleSubmitSearch}
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.21031 15.9379C12.1871 15.9379 15.4108 12.7142 15.4108 8.7374C15.4108 4.76066 12.1871 1.53687 8.21031 1.53687C4.23356 1.53687 1.00977 4.76066 1.00977 8.7374C1.00977 12.7142 4.23356 15.9379 8.21031 15.9379Z"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.2107 17.7378L13.2954 13.8225"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {/* <button onClick={() => refine('')}>Reset query</button> */}
      </form>
      <img
        className={styles.close}
        onClick={handleCloseSearchClick}
        style={{
          cursor: "pointer",
        }}
        src={close}
        alt="close"
      />
    </div>
  );
};
export default memo(SearchBox);
